import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class EhealthProPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "ehealthpro_noti_center_slider_phone1.png",
      "ehealthpro_noti_center_slider_phone2.png",
    ];
    const sliderImageAlts = [
      "Screenshot of eHealthPro",
      "Screenshot of eHealthPro",
    ];
    const sliderImageIds = [
      "img1eHealthProNotiCenterSlider",
      "img2eHealthProNotiCenterSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"pro-noti-center-bg.png"}
        sliderID="eHealthProSlider"
        sliderTitle={t("Pro Notification Center")}
        sliderTitleID={"texteHealthProAccSliderTitle"}
        sliderText={t("Pro View eHRSS notification easily")}
        sliderTextID="texteHealthProSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} ehealthpro-color`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid  ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("eHRSS Inbox Reminder")}
                </div>
                <div
                  id="texteHealthProSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Help you to keep track of unread messages in your eHRSS Inbox"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="100"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_center_p1_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_center_p1_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ehealthpro-2nd-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div className="ehealthpro-title step-horizontal-title">
                  <img
                    className="pro-tips-image"
                    alt={t("Tap “Request Access Right”")}
                    src={load(
                      `./resources/images/ehealth-pro/pro-noti-center-tips.png`
                    )}
                  />
                  {t("Useful Tips")}
                </div>
                <div className=" step-horizontal-subtitle">
                  {t(
                    "Login to the desktop version of eHRSS to view your inbox message effortlessly"
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 ehealthpro-step-horizontal ">
              <div className=" align-self-center">
                <img
                  className="pro-feature-img"
                  alt={t("Tap “Request Access Right”")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_tips_step_1.png`
                  )}
                />
              </div>
              <div className="step-horizontal-content ">
                {t('Tap "Login to eHRSS" hotkey in the eHealth App')}
              </div>
              <img
                className="step-horizontal-arrow"
                src={load(
                  `./resources/images/ehealth-pro/step-horizontal-arrow.png`
                )}
              />
            </div>
            <div className="col-md-4 ehealthpro-step-horizontal ">
              <div className=" align-self-center">
                <img
                  className="pro-feature-img"
                  alt={t("Tap “Request Access Right”")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_tips_step_2.png`
                  )}
                />
              </div>
              <div className="step-horizontal-content ">
                {t("Login with Biometric ID")}
              </div>
              <img
                className="step-horizontal-arrow"
                src={load(
                  `./resources/images/ehealth-pro/step-horizontal-arrow.png`
                )}
              />
            </div>

            <div className="col-md-4 ehealthpro-step-horizontal ">
              <div className=" align-self-center">
                <img
                  className="pro-feature-img"
                  alt={t("Tap “Request Access Right”")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_tips_step_3.png`
                  )}
                />
              </div>
              <div className="step-horizontal-content ">
                {t(
                  "Scan the QR code on the desktop version of eHRSS to access"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div className="second-feature-title">
                  {t("Professional Broadcast")}
                </div>
                <div className="second-feature-text">
                  {t(
                    "Keep you updated with the latest information and important news"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_center_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_noti_center_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EhealthProPage);
