import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class LifeTutorial extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  constructor(props) {
    super(props);
    this.state = {
      tab: "",
      tabs: [
        {
          key: "above",
          name: "16 years old or above",
        },
        {
          key: "under",
          name: "Under 16 years old",
        },
      ],
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
  }

  handleSwitchTab(tab) {
    this.setState({
      tab,
    });
  }

  componentDidMount() {
    this.setState({
      tab: this.state.tabs[0].key,
    });
  }

  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    const { locale, t } = this.props;
    let { appStoreDownloadURL } = this.props;
    let { registerLink } = this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/register-online.html";
    }

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/us/app/e-life/id6502993463";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/cn/app/e-life/id6502993463";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/hk/app/e-life/id6502993463";
    }

    return (
      <div
        className={` page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/${locale}/under16-gift-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/${locale}/under16-gift-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container-fluid under16-earn-coins-container">
          <div className="container">
            <h3 className="">
              <img
                className="under16-icon"
                src={load(`./resources/images/under16-earn-coin-icon.png`)}
              />
              {t("Earn health coins to redeem gifts!")}
            </h3>

            <div className="col-sm-6 under16-earn-coins-col">
              <div className="under16-earn-coins-col-content">
                <img
                  src={load(`./resources/images/under16-mission1-graphic.png`)}
                />
                <span className="mission-tag">{t("Mission 1")}</span>
                <span className="mission-detail">
                  {t("mission detail mission 1")}
                </span>
                <span className="mission-date">
                  {t("mission date mission 1")}
                </span>
                <a
                  href="/caregiver#one-stop-registration"
                  target="_block"
                  className="mission-btn"
                >
                  {t("mission btn mission 1")}
                </a>
              </div>
            </div>

            <div className="col-sm-6 under16-earn-coins-col">
              <div className="under16-earn-coins-col-content">
                <img
                  src={load(`./resources/images/under16-mission2-graphic.png`)}
                />
                <span className="mission-tag">{t("Mission 2")}</span>
                <span className="mission-detail">
                  {t("mission detail mission 2")}
                </span>
                <span className="mission-date">
                  {t("mission date mission 2")}
                </span>
                <a
                  href="/caregiver#add-family-member"
                  target="_block"
                  className="mission-btn"
                >
                  {t("mission btn mission 2")}
                </a>
              </div>
            </div>

            <div className="col-sm-12 ">
              <a
                href="/elife-redemption"
                target="_block"
                className="mission-btn"
              >
                {t("mission btn below")}
              </a>
            </div>
          </div>
        </div>

        <div className="container-fluid birth-kit-container">
          <div className="container">
            <img
              className="birth-kit-icon"
              src={load(`./resources/images/under16-birth-kit-icon.png`)}
            />
            <h3 className="">{t("Birth kit title")}</h3>
            <img
              className="main-graphic"
              src={load(`./resources/images/under16-birth-kit-graphic.png`)}
            />
            <div className="row gift-redemption-intro">
              {t("Birth kit intro")}
              <span>{t("Birth kit remarks")}</span>
            </div>
          </div>
        </div>

        <div className="container-fluid disclaimer-container">
          <div className="container">
            {t("under 16 disclaimer title")}
            <br />
            <ol>
              <li>{t("under 16 disclaimer 1")}</li>
              <li>{t("under 16 disclaimer 2")}</li>
              <li>{t("under 16 disclaimer 3")}</li>
              <li>{t("under 16 disclaimer 4")}</li>
              <li>{t("under 16 disclaimer 5")}</li>
              <li>{t("under 16 disclaimer 6")}</li>
              <li>{t("under 16 disclaimer 7")}</li>
              <li>{t("under 16 disclaimer 8")}</li>
            </ol>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeTutorial);
