import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class CaregiverPage extends Component {
  state = { active: false };

  handleClick = () => {
    this.setState((currentState) => {
      return { active: !currentState.active };
    });
  };

  render() {
    const ExpandPanelComponent = ({
      question,
      step1img,
      step1text,
      step2img,
      step2text,
      methodIcon,
    }) => {
      const [isActive, setActive] = React.useState(false);

      return (
        <div className="add-member-method-box">
          <div
            onClick={() => setActive(!isActive)}
            className={`expand-panel-title ${isActive ? "active" : ""}`}
          >
            <img
              className="add-family-method-icon"
              src={load(`./resources/images/${methodIcon}.png`)}
            />

            <h4>{question}</h4>

            <img
              className="faq-expand-icon"
              src={load(
                `${
                  isActive
                    ? "./resources/images/collapse-button.png"
                    : "./resources/images/collapse-button-open.png"
                }`
              )}
            />
          </div>
          <div
            id="member-above-16-scan-qr"
            className={`expand-panel-content ${isActive ? "active" : ""}`}
          >
            <div className="row vertical-align">
              <div className="col-sm-4">
                <img
                  src={load(`./resources/images/${locale}/${step1img}.png`)}
                />
              </div>
              <div className="col-sm-8">
                <div className="step-number">1</div>
                {step1text}
              </div>
            </div>

            <div className="row vertical-align">
              <div className="col-sm-4">
                <img
                  src={load(`./resources/images/${locale}/${step2img}.png`)}
                />
              </div>
              <div className="col-sm-8">
                <div className="step-number">2</div>
                {step2text}
              </div>
            </div>
          </div>
        </div>
      );
    };
    const { locale, t } = this.props;
    const sliderImages = [
      "caregiver_slider_phone1.png",
      "caregiver_slider_phone2.png",
      "caregiver_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of My Family",
      "Screenshot of My Family",
      "Screenshot of My Family",
    ];
    const sliderImageIds = [
      "img1MyFamilySlider",
      "img2MyFamilySlider",
      "img3MyFamilySlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"caregiver-bg.png"}
        sliderID="myFamilySlider"
        sliderTitle={t("My Family Header")}
        sliderTitleID={"textMyFamilySliderTitle"}
        sliderText={t("The health of your family matters")}
        sliderTextID="textMyFamilySliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );

    let { otherMethodPage } = this.props;
    if (locale === "en") {
      otherMethodPage =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-CN") {
      otherMethodPage =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-HK") {
      otherMethodPage =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/register-online.html";
    }
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid my-family-orange-section">
          <a className="scroll-anchor" id="one-stop-registration"></a>
          <div className="container my-family-caree-container">
            <h2 className="life-section-title">
              {t("One-stop registration of eHealth for your children under 16")}
            </h2>
            <div className="birth-cert-registration-step row">
              <div className="col-sm-3 birth-cert-step">
                <img
                  src={load(
                    `./resources/images/${locale}/birth-cert-step1.png`
                  )}
                />
                {t("birth cert step 1")}
              </div>
              <div className="col-sm-3 birth-cert-step">
                <img
                  src={load(
                    `./resources/images/${locale}/birth-cert-step2.png`
                  )}
                />
                {t("birth cert step 2")}
              </div>
              <div className="col-sm-3 birth-cert-step">
                <img
                  src={load(
                    `./resources/images/${locale}/birth-cert-step3.png`
                  )}
                />
                {t("birth cert step 3")}
              </div>
              <div className="col-sm-3 birth-cert-step">
                <img
                  src={load(
                    `./resources/images/${locale}/birth-cert-step4.png`
                  )}
                />
                {t("birth cert step 4")}
              </div>
            </div>
            <div className="birth-cert-reminder-container row">
              <div className="col-sm-3">
                <img
                  src={load(`./resources/images/birth-cert-reminder-icon.png`)}
                />
              </div>
              <div className="col-sm-9 birth-cert-reminder">
                {t("My family birth cert reminder content")}
              </div>
            </div>
            <div className="birth-cert-other-method">
              {t("child register other method")}
              <a href={otherMethodPage} target="_block">
                {t("child register other method button")}
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid my-family-orange-section">
          <a className="scroll-anchor" id="add-family-member"></a>
          <div className="container">
            <h2 className="life-section-title add-member-lady-deco">
              {t("Add my family members")}
            </h2>
          </div>
        </div>

        <div
          id="add-family-detail-container"
          className="container-fluid my-family-orange-section"
        >
          <div className="container">
            <div className="add-family-section">
              <div className="add-16-above">
                <div className="add-family-title">
                  <img
                    src={load(`./resources/images/my-family-add-16-above.png`)}
                  />
                  {t("Add family member aged 16 or above")}
                </div>
                <div className="expand-panel-container">
                  <ExpandPanelComponent
                    question={t("add member 16 above scan qr code")}
                    methodIcon={t("add-family-scan-code-icon")}
                    step1img="16-above-scan-1"
                    step1text={t("add member 16 above scan qr code step 1")}
                    step2img="16-above-scan-2"
                    step2text={t("add member 16 above scan qr code step 2")}
                  />
                  <ExpandPanelComponent
                    question={t("add member 16 above input info")}
                    methodIcon={t("add-family-input-info-icon")}
                    step1img="16-above-input-info-1"
                    step1text={t("add member 16 above input info step 1")}
                    step2img="16-above-input-info-2"
                    step2text={t("add member 16 above input info step 2")}
                  />
                </div>
              </div>
              <div className="add-under-16">
                <div className="add-family-title">
                  <img
                    src={load(`./resources/images/my-family-add-under16.png`)}
                  />
                  {t("Add family member aged under 16")}
                </div>
                <div className="expand-panel-container">
                  <ExpandPanelComponent
                    question={t("add member under 16 input info")}
                    methodIcon={t("add-family-input-info-icon")}
                    step1img="16-above-input-info-1"
                    step1text={t("add member under 16 input info step 1")}
                    step2img="under-16-input-info-2"
                    step2text={t("add member under 16 input info step 2")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textMyFamilySection1Title"
                  className="second-feature-title"
                >
                  {t("Keep the health of your family within easy reach")}
                </div>
                <div
                  id="textMyFamilySection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Support management of medication, allergy and vaccine records for your family"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("Accompany your children on their growth journey")}
                </div>
                <div
                  id="textMyFamilySection2Content"
                  className="second-feature-text"
                >
                  {t("Keep track of your children's growth and development")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textMyFamilySection3Title"
                  className="second-feature-title"
                >
                  {t("A proper management of appointment records")}
                </div>
                <div
                  id="textMyFamilySection3Content"
                  className="second-feature-text"
                >
                  {t(
                    "Helps you to keep track of all appointments records of your family"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p3_1.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p3_2.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p3_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoMyFamily"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/I4IUv2dn_h8"
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        {/* <div className="container-fluid caregiver-tips" style={{display: "none"}}> */}
        <div className="container-fluid caregiver-tips">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-right"
                className="col-md-9 col-sm-12 col-sm-push-3"
              >
                <div
                  id="textMyFamilySection4Title"
                  className="second-usage-title"
                >
                  {t("How can I add a family member?")}
                </div>
                <div
                  id="textMyFamilySection4Content"
                  className="second-usage-text"
                >
                  {t(
                    "You may find relevant details on the above tutorial or on the My Family Members tab"
                  )}
                </div>
              </div>
              <div
                data-aos="fade-up"
                className="col-md-3 col-sm-12 col-sm-pull-9"
              >
                <img
                  id="img1MyFamilySection4"
                  src={load(`./resources/images/${locale}/caregiver_tip_1.png`)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(CaregiverPage);
